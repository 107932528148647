<template>
  <v-form
    :lazy-validation="false"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
  >
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("report.create") }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          :label="$t('report.fields.name')"
          name="name"
          prepend-icon="mdi-domain"
          type="text"
          v-model="name"
          :rules="nameRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          autocomplete="off"
          :label="$t('report.fields.reportContacts')"
          v-model="reportContacts"
          :items="companyUsers"
          :rules="autocompleteRules"
          required
          item-text="firstname"
          item-value="userId"
          multiple
          prepend-icon="mdi-account-box"
          class="pt-0 mt-0"
        >
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.firstname"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.lastname"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-card-text>
        <v-select
          prepend-icon="mdi-all-inclusive"
          v-model="tempo"
          :items="tempoItems"
          :label="$t('report.fields.tempo')"
          item-text="name"
          item-value="value"
        >
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.desc"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-select>
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          autocomplete="off"
          :disabled="status.loading"
          :loading="status.loading"
          :label="$t('report.fields.type')"
          v-model="type"
          :items="reportTypes"
          item-text="name"
          item-value="value"
          prepend-icon="mdi-form-select"
          class="pa-0 ma-0"
          required
        ></v-autocomplete>
      </v-card-text>

      <v-divider />

      <v-card-text class="pb-0">
        <v-autocomplete
          autocomplete="off"
          :loading="tagStatus.loading"
          :label="$t('alarm.fields.tag')"
          v-model="tag"
          :items="tags"
          item-text="name"
          item-value="deveui"
          prepend-icon="mdi-cellphone-link"
          class="pb-0 mb-0"
        ></v-autocomplete>
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          autocomplete="off"
          :disabled="tagStatus.loading || tag === null"
          :loading="tagDataStatus.loading"
          :label="$t('alarm.fields.tagDataKey')"
          v-model="tagDataKey"
          :items="keys"
          item-text="label"
          item-value="key"
          prepend-icon="mdi-key"
          class="pt-0 pb-0 mt-0"
        ></v-autocomplete>
      </v-card-text>

      <v-btn outlined color="accent" text @click="newInclude()">
        {{ $t("common.add") + " sensor" }}
      </v-btn>

      <v-simple-table v-if="this.includedTags.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t("alarm.fields.tag") }}</th>
              <th class="text-left">{{ $t("module.meta.key") }}</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(m, i) in includedTags" :key="i">
              <td class="text-left">{{ m.deveui }}</td>
              <td class="text-left">
                {{ m.key }}
              </td>
              <td>
                <v-btn color="error" fab x-small @click="removeListItem(i)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-actions>
        <v-btn
          color="primary"
          type="submit"
          :disabled="!valid || includedTags.length === 0"
          x-large
        >
          {{ $t("common.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ReportCreate",
  data() {
    return {
      submitted: false,
      valid: false,

      name: "",
      tempo: 0,
      type: 0,
      tagDataKey: "",
      tag: null,
      reportContacts: [],
      includedTags: [],

      tempoItems: [
        {
          name: this.$t("report.tempo.0"),
          value: 0,
          desc: this.$t("report.tempo.desc.0"),
        },
        {
          name: this.$t("report.tempo.1"),
          value: 1,
          desc: this.$t("report.tempo.desc.1"),
        },
        {
          name: this.$t("report.tempo.2"),
          value: 2,
          desc: this.$t("report.tempo.desc.2"),
        },
      ],

      reportTypes: [
        { name: this.$t("report.types.regular"), value: 0 },
        { name: this.$t("report.types.trend"), value: 1 },
      ],

      nameRules: [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
        (v) => (v && v.length <= 255) || this.$t("alarm.validation.max"),
      ],

      messageRules: [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
        (v) => (v && v.length <= 255) || this.$t("alarm.validation.max"),
      ],

      autocompleteRules: [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
      ],
    };
  },

  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("tag", { tagStatus: "status", tags: "tags" }),
    ...mapState("tagData", { tagDataStatus: "status", keys: "keys" }),
    ...mapState("companies", {
      companyUsers: "companyUsers",
      companyStatus: "status",
    }),
  },

  methods: {
    ...mapActions("tag", ["getTags", "getKeysForTag"]),
    ...mapActions("companies", ["getCompanyUsers"]),
    ...mapActions("reports", ["create"]),

    removeListItem(i) {
      this.includedTags.splice(i, 1);
    },

    async newInclude() {
      if (!this.tag || !this.tagDataKey) return;
      this.includedTags.push({
        key: this.tagDataKey,
        deveui: this.tag,
      });

      this.tag = null;
      this.tagDataKey = null;
    },

    // eslint-disable-next-line
    async handleSubmit(e) {
      if (!this.includedTags.length > 0) {
        this.valid = false;
        return;
      }

      var payloadTags = [];
      var payloadKeys = [];
      for (var i = 0; i < this.includedTags.length; i++) {
        payloadTags.push(this.includedTags[i].deveui);
        payloadKeys.push(this.includedTags[i].key);
      }

      this.submitted = true;

      const { name, type, tempo, reportContacts } = this;

      var payload = {
        name: name,
        type: type,
        tempo: tempo,
        keys: payloadKeys,
        reportContacts: reportContacts,
        tagIds: payloadTags,
      };

      if (this.valid) await this.create({ payload: payload });

      this.$router.push("/report");
    },
  },

  created() {
    this.getTags();
    this.getCompanyUsers();
  },

  watch: {
    // eslint-disable-next-line
    tag(val, oldVal) {
      if (val != null) this.getKeysForTag({ tagId: val });
    },
  },
};
</script>

<style></style>
